<template>
  <div class="base-content">
    <query-frame
      :pageVisible="false"
      :searchVisible="false"
      :add-visible="false"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import queryMixin from '@/mixin/queryMixin';
import { yearList } from '../../api/year';

export default {
  name: 'VipList',
  mixins: [queryMixin],
  data() {
    return {
      dictOptions: {},
      dataList: [],
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '卡名称',
          dataIndex: 'yearCardName',
          key: 'yearCardName',
          ellipsis: true,
        },
        {
          title: '价格',
          dataIndex: 'price',
          key: 'price',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children:
                <section>
                  <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                </section>,
            };
          }
        },
      ],
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    // 获取列表
    getData() {
      this.dataList = [];
      yearList().then((data) => {
        // console.log(data);
        data.forEach((item) => {
          item.key = item.yearCardId;
        });
        this.dataList = data;
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'vipEdit',
        params: {
          id: row.yearCardId
        }
      });
    },
  }
};
</script>

<style scoped>

</style>
